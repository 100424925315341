"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LocalStorage {
    static set(key, value) {
        if (this.isSupported) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }
    static get(key) {
        if (this.isSupported) {
            return JSON.parse(localStorage.getItem(key) || '""');
        }
        return null;
    }
    static remove(key) {
        if (this.isSupported) {
            localStorage.removeItem(key);
        }
    }
    static clear() {
        if (this.isSupported) {
            localStorage.clear();
        }
    }
}
exports.default = LocalStorage;
LocalStorage.isSupported = typeof window['localStorage'] != "undefined" && window['localStorage'] != null;
