import { Component, ViewChild, inject, DestroyRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { OnInit } from '@angular/core';
import {
  PeakAvatarModule,
  PeakButtonModule,
  PeakDialogService,
  PeakHeaderModule,
} from 'peak-ui';
import { CommonModule } from '@angular/common';
import { Menu } from './core/models/menu.model';
import { TranslateService } from '../shared/services/translate.service';
import { MeService } from '../shared/services/me.service';
import { PeakShellModule } from 'peak-shell';
import { CurrentUser } from '../shared/models/current-user.model';
import { UserRole } from './core/models/user-role.enum';
import { NotificationService } from '../shared/services/notification.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CalculateUserRoleDirective } from '../shared/directives/calculate-roles.directive';
import { TranslatePipe } from './core/pipes/translate.pipe';
import { EditLocationComponent } from './modules/home/pages/components/edit-location/edit-location.component';
import { RegionalService } from '../shared/services/regional.service';
import LanguageService from '../shared/services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoadingComponent } from '../shared/components/loading/loading.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    PeakHeaderModule,
    CommonModule,
    PeakAvatarModule,
    PeakButtonModule,
    PeakShellModule,
    CalculateUserRoleDirective,
    TranslatePipe,
    ConfirmDialogModule,
    LoadingComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private meService = inject(MeService);
  private destroyRef = inject(DestroyRef);
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
  profileImageUri: string = '../assets/img/empty-profile-picture.png';
  leftOverlayButtonIcon = 'pi pi-question';
  middleOverlayButtonIcon = 'pi pi-th-large';
  rightOverlayButtonIcon = 'pi pi-bell';
  menuItems = [
    {
      label: 'vlc:Admin:Homepage',
      icon: '',
      routerLink: ['/settings'],
    },
    {
      label: 'vlc:Admin:Settings',
      icon: '',
      items: [
        {
          label: 'vlc:Admin:Company',
          icon: 'pi pi-building',
          routerLink: ['/settings'],
        },
        {
          label: 'vlc:Admin:Notifications',
          icon: 'pi pi-bell',
          routerLink: ['settings/notification'],
        },
        {
          label: 'vlc:Admin:Integrations',
          icon: 'pi pi-link',
          routerLink: ['/settings'],
        },
        {
          label: 'vlc:Admin:Manage',
          icon: 'pi pi-users',
          routerLink: ['settings/management'],
        },
        {
          label: 'vlc:Admin:Themes',
          icon: 'pi pi-palette',
          routerLink: ['/settings'],
        },
      ],
    },
    {
      label: 'vlc:Admin:Intranet',
      icon: '',
      routerLink: ['/settings'],
    },
  ];

  avatarMenu: Menu[] = [
    {
      label: 'vlc:Admin:Company.Settings.Language.Label',
      icon: 'pi pi-angle-left',
      items: [
        {
          label: 'Türkçe',
          icon: 'custom-icon-turkey',
          command: () => {
            this.setLanguage('tr-TR');
            this.notificationService.success(
              'Dil Türkçe olarak değiştirildi',
              'Başarılı'
            );
          },
        },
        {
          label: 'English',
          icon: 'custom-icon-us',
          command: () => {
            this.setLanguage('en-US');
            this.notificationService.success(
              'Language changed to English.',
              'Success'
            );
          },
        },
        {
          label: 'Español',
          icon: 'custom-icon-spain',
          command: () => {
            this.setLanguage('es-ES');
            this.notificationService.success(
              'Idioma cambiado como Español',
              'Éxito'
            );
          },
        },
      ],
    },
  ];
  langChangeSubscription: any;
  selectedLanguage: string = '';
  translatedMenu: Menu[] = [];
  profile: CurrentUser = new CurrentUser();
  userName: any;
  oncommand: string = '';
  Logout: any;
  userRole: string = '';
  isMasterAdmin: any;
  authProfile: any;
  translatedAvatarMenu: Menu[] = [];
  permissionScope: UserRole = UserRole.User;
  endUser: boolean = false;
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private peakDialogService: PeakDialogService,
    private regionalService: RegionalService
  ) {
    this.getUserInfo();
  }

  ngOnInit(): void {
    this.languageService.onLanguageChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.translatedMenu = this.translateMenu(this.menuItems);
        this.translatedAvatarMenu = this.translateMenu(this.avatarMenu);
      });
  }

  translateMenu(menuItems: Menu[]): Menu[] {
    const translateItem = (item: Menu): Menu => {
      const translatedItem = { ...item };
      translatedItem.label = this.translateService.translateInstant(item.label);
      if (translatedItem.items) {
        translatedItem.items = translatedItem.items.map((subItem) =>
          translateItem(subItem)
        );
      }
      return translatedItem;
    };

    return menuItems.map(translateItem);
  }

  async setLanguage(language: string) {
    this.selectedLanguage = language;
    await this.translateService.setCurrentLanguage(language);
    await this.languageService.setCurrentLanguage(language);
    this.languageService.saveLanguageToLocalStorage(language);
    this.regionalService.registerCulture(language);
    // this.overlayPanel.hide();
  }

  getResolvedProfile(me: any) {
    this.authProfile = me;
    this.isMasterAdmin = this.authProfile?.isMasterAdmin;
  }

  getUserInfo() {
    this.meService.currentUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.profile = user;
        this.permissionScope = user.PermissionScope;
      });
  }
  getLogoutAction(logout: any) {
    this.Logout = logout;
    this.oncommand = logout[0].text;
  }

  editLocation() {
    this.peakDialogService.open(EditLocationComponent, {
      header: this.translateService.translateInstant(
        'vlc:Admin:Company.Settings.Language.Label'
      ),
      height: '230px',
      dismissableMask: false,
      showHeader: true,
      closeOnEscape: false,
      maximizable: false,
    });
  }

  logout() {
    if (this.Logout[1].type == 'Master') {
      this.Logout[1].command();
    } else if (this.Logout[0].type == 'Normal') {
      this.Logout[0].command();
    }
  }
}
