"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const PeakDataClient_1 = require("./PeakDataClient");
class PeakDataHolidayClient {
    constructor(client) {
        this.client = client;
        this.endpoint = "api/Holiday/:action";
        this.getAction = "Get";
        this.postAction = "Post";
        this.putAction = "Put";
        this.deleteAction = "Delete";
    }
    getAllAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getAsync({
                path: this.endpoint,
                namedQueries: { action: this.getAction }
            });
        });
    }
    getAll() {
        return PeakDataClient_1.asyncScheduled(this.getAllAsync());
    }
    getAsync(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getAsync({
                path: this.endpoint,
                namedQueries: { action: this.getAction },
                queryStrings: { id }
            });
        });
    }
    get(id) {
        return PeakDataClient_1.asyncScheduled(this.getAsync(id));
    }
    postAsync(model) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.postAsync({
                path: this.endpoint,
                namedQueries: { action: this.postAction },
                body: model
            });
        });
    }
    post(model) {
        return PeakDataClient_1.asyncScheduled(this.postAsync(model));
    }
    putAsync(model) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.putAsync({
                path: this.endpoint,
                namedQueries: { action: this.putAction },
                body: model
            });
        });
    }
    put(model) {
        return PeakDataClient_1.asyncScheduled(this.putAsync(model));
    }
    deleteAsync(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.deleteAsync({
                path: this.endpoint,
                namedQueries: { action: this.deleteAction },
                queryStrings: { Id: id }
            });
        });
    }
    delete(id) {
        return PeakDataClient_1.asyncScheduled(this.deleteAsync(id));
    }
}
exports.default = PeakDataHolidayClient;
