"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PeakAuthConstants {
}
exports.default = PeakAuthConstants;
// Query parameters
PeakAuthConstants.currentTenantMoniker = "myorganization";
PeakAuthConstants.currentUserMoniker = "me";
PeakAuthConstants.service = "service";
PeakAuthConstants.code = "code";
PeakAuthConstants.refreshCode = "refresh_code";
PeakAuthConstants.requestCode = "request_code";
PeakAuthConstants.redirectUri = "redirect_uri";
PeakAuthConstants.autoLogin = "auto_login";
PeakAuthConstants.loginHint = "login_hint";
PeakAuthConstants.status = "status";
PeakAuthConstants.adminConsent = "admin_consent";
PeakAuthConstants.grantType = "grant_type";
PeakAuthConstants.with = "with";
PeakAuthConstants.error = "error";
PeakAuthConstants.process = "process";
PeakAuthConstants.source = "source";
PeakAuthConstants.sourceIdentifier = "source_identifier";
// Status
PeakAuthConstants.completed = "completed";
// Variables
PeakAuthConstants.peakauthconfig = "peakauthconfig";
// Meta tags
PeakAuthConstants.peakservice = "peak:service";
PeakAuthConstants.peakuri = "peak:uri";
PeakAuthConstants.peakauth = "peak:auth";
