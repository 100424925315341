import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  delayWhen,
  firstValueFrom,
  mergeMap,
  of,
  retry,
  take,
  timer,
} from 'rxjs';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { CurrentUser } from '../models/current-user.model';
import LanguageService from './language.service';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  baseUrl: string = environment.BaseUri;
  private _currentUserSubject = new BehaviorSubject<CurrentUser>(
    new CurrentUser()
  );
  public currentUser$: Observable<CurrentUser> =
    this._currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
    private notifyService: NotificationService,
    private languageService: LanguageService
  ) {
    this.fetchAndSetUser();
  }

  get currentUser() {
    return this._currentUserSubject.value;
  }

  me() {
    return this.http.get(`${this.baseUrl}/api/user/me`);
  }

  fetchAndSetUser(attempts: number = 0) {
    console.log('Fetching user data..');

    this.me()
      .pipe(
        mergeMap((response: any) => {
          if (response) {
            return of(response);
          } else {
            throw new Error('User data is null or undefined.');
          }
        }),
        catchError((error: any) => {
          console.error('Failed to fetch user data:', error);
          return of(null);
        }),
        retry(3), // Retry up to 3 times
        delayWhen(() => timer(1000))
      )
      .subscribe((response: any) => {
        if (response) {
          const user = response.ObjectResult as CurrentUser;
          this.handleUser(user);
        } else {
          if (attempts < 2) {
            this.fetchAndSetUser(attempts + 1);
          } else {
            console.error('Exceeded maximum retry attempts.');
          }
        }
      });
  }

  async handleUser(user: CurrentUser) {
    this._currentUserSubject.next(user);
    this.handleUserLanguage(user);
  }

  async handleUserLanguage(user: CurrentUser) {
    if (this.languageService.getLanguageFromLocalStorage() == undefined) {
      if (user.Language.ISOCode) {
        this.languageService.setCurrentLanguage(user.Language.ISOCode);
      } else {
        const tenantLanguage = await this.getTenantLanguage();
        if (tenantLanguage) {
          this.languageService.setCurrentLanguage(tenantLanguage);
        }
      }
    }
  }

  async getTenantLanguage(): Promise<string | undefined> {
    const result = await firstValueFrom(
      this.http.get<any>(`${this.baseUrl}/api/language/GetTenantLanguages`)
    );

    if (result.ObjectResult?.TenantLanguageId) {
      const tenantLanguageId = result.ObjectResult?.TenantLanguageId;
      const tenantLanguage = result.Results?.find(
        (l: any) => l.Id == tenantLanguageId
      );
      if (tenantLanguage) {
        return tenantLanguage.ISOCode;
      }
    }

    return undefined;
  }
}
