"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const PeakDataClient_1 = require("./PeakDataClient");
class PeakDataCurrencyClient {
    constructor(client) {
        this.client = client;
        this.endpoint = "api/Currency/:action";
        this.getTodayAction = "GetToday";
    }
    getAllAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getAsync({
                path: this.endpoint,
                namedQueries: { action: this.getTodayAction }
            });
        });
    }
    getAll() {
        return PeakDataClient_1.asyncScheduled(this.getAllAsync());
    }
    getAsync(currencyCode) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getAsync({
                path: this.endpoint,
                namedQueries: { action: this.getTodayAction },
                queryStrings: { Single: currencyCode }
            });
        });
    }
    get(currencyCode) {
        return PeakDataClient_1.asyncScheduled(this.getAsync(currencyCode));
    }
}
exports.default = PeakDataCurrencyClient;
