"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ApiResponse {
    constructor(singleResult, results = new Array(), errors) {
        this.singleResult = singleResult;
        this.results = results;
        this.errors = errors;
    }
}
exports.default = ApiResponse;
