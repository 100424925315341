import * as i0 from '@angular/core';
import { Component, Input, EventEmitter, Output, InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import PeakAuth from 'peakauth';
import PeakData from 'peak-data';
import { Subject } from 'rxjs';
import * as teams from '@microsoft/teams-js';
function PeakUserActionComponent_a_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵlistener("click", function PeakUserActionComponent_a_0_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.value.command());
    });
    i0.ɵɵelement(1, "i");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r0.value.icon);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.value.text);
  }
}
function PeakUserActionComponent_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 1);
    i0.ɵɵlistener("click", function PeakUserActionComponent_a_1_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.value.command());
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵelement(2, "i");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 3);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.value.icon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.value.text);
  }
}
function PeakUserComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2("", ctx_r0.profile.user.name || "", " ", ctx_r0.profile.user.surname || "", "");
  }
}
function PeakUserComponent_div_1_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "peak-user-action", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const action_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", action_r3);
  }
}
function PeakUserComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "button", 5);
    i0.ɵɵlistener("click", function PeakUserComponent_div_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.isOpened = !ctx_r4.isOpened);
    });
    i0.ɵɵelementStart(2, "span", 6);
    i0.ɵɵelement(3, "img", 7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(4, "div", 8);
    i0.ɵɵtemplate(5, PeakUserComponent_div_1_ng_container_5_Template, 2, 1, "ng-container", 9);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("src", ctx_r1.profile.user.profileImageUri || "../../../../assets/img/user-icon.png", i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("show", ctx_r1.isOpened);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.actions);
  }
}
function PeakHeaderComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵelement(1, "img", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("src", ctx_r0.logoUri, i0.ɵɵsanitizeUrl);
  }
}
function PeakHeaderComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14)(1, "a");
    i0.ɵɵelement(2, "img", 15);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", ctx_r1.logoUri, i0.ɵɵsanitizeUrl);
  }
}
function PeakHeaderComponent_div_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 16);
    i0.ɵɵelement(1, "peak-user", 17);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("profile", ctx_r2.profile)("actions", ctx_r2.actions);
  }
}
function PeakHeaderComponent_div_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 18)(1, "a", 19);
    i0.ɵɵlistener("click", function PeakHeaderComponent_div_13_Template_a_click_1_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4._onLogin());
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate("peak:login");
  }
}
function PeakShellComponent_peak_header_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "peak-header", 1);
    i0.ɵɵlistener("onLogin", function PeakShellComponent_peak_header_0_Template_peak_header_onLogin_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.login());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("showLogo", ctx_r0.showLogo)("profile", ctx_r0.profile)("actions", ctx_r0.resolvedActions);
  }
}
class PeakUserActionComponent {
  constructor() {}
  ngOnInit() {}
}
PeakUserActionComponent.ɵfac = function PeakUserActionComponent_Factory(t) {
  return new (t || PeakUserActionComponent)();
};
PeakUserActionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PeakUserActionComponent,
  selectors: [["peak-user-action"]],
  inputs: {
    value: "value"
  },
  decls: 2,
  vars: 2,
  consts: [["class", "dropdown-item", 3, "click", 4, "ngIf"], [1, "dropdown-item", 3, "click"], [1, "pull-left", "m-r-10"], [1, "pull-left"], [1, "clearfix"]],
  template: function PeakUserActionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PeakUserActionComponent_a_0_Template, 3, 4, "a", 0)(1, PeakUserActionComponent_a_1_Template, 6, 4, "a", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.value.type == "Normal");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.value.type == "Master");
    }
  },
  dependencies: [i1.NgIf]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakUserActionComponent, [{
    type: Component,
    args: [{
      selector: 'peak-user-action',
      template: " \r\n  <a (click)=\"value.command()\" *ngIf=\"value.type == 'Normal'\" class=\"dropdown-item\">\r\n    <!-- TODO: value icon i\u00E7in image deste\u011Fi eklenecek. -->\r\n    <i class=\"{{value.icon}}\"></i>{{value.text}}</a>\r\n\r\n  <a class=\"dropdown-item\" (click)=\"value.command()\" *ngIf=\"value.type == 'Master'\">\r\n    <span class=\"pull-left m-r-10\">\r\n      <i class=\"{{value.icon}}\"></i>\r\n    </span>\r\n    <span class=\"pull-left\">{{value.text}}</span>\r\n    <div class=\"clearfix\"></div>\r\n  </a>"
    }]
  }], function () {
    return [];
  }, {
    value: [{
      type: Input
    }]
  });
})();
class PeakUserComponent {
  constructor() {
    this.isOpened = false;
  }
  ngOnInit() {}
}
PeakUserComponent.ɵfac = function PeakUserComponent_Factory(t) {
  return new (t || PeakUserComponent)();
};
PeakUserComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PeakUserComponent,
  selectors: [["peak-user"]],
  inputs: {
    profile: "profile",
    actions: "actions",
    isOpened: "isOpened"
  },
  decls: 2,
  vars: 2,
  consts: [["class", "pull-left p-r-10 p-t-10 fs-16 font-heading", 4, "ngIf"], ["class", "dropdown pull-right", 4, "ngIf"], [1, "pull-left", "p-r-10", "p-t-10", "fs-16", "font-heading"], [1, "bold"], [1, "dropdown", "pull-right"], ["type", "button", "data-toggle", "dropdown", "aria-haspopup", "true", "aria-expanded", "false", 1, "profile-dropdown-toggle", "dropdown-toggle", 3, "click"], [1, "thumbnail-wrapper", "d32", "circular", "inline", "m-t-5"], [3, "src"], ["role", "menu", 1, "dropdown-menu", "dropdown-menu-right", "profile-dropdown"], [4, "ngFor", "ngForOf"], [3, "value"]],
  template: function PeakUserComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PeakUserComponent_div_0_Template, 3, 2, "div", 0)(1, PeakUserComponent_div_1_Template, 6, 4, "div", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.profile);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.profile);
    }
  },
  dependencies: [i1.NgForOf, i1.NgIf, PeakUserActionComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakUserComponent, [{
    type: Component,
    args: [{
      selector: 'peak-user',
      template: "<div *ngIf=\"profile\" class=\"pull-left p-r-10 p-t-10 fs-16 font-heading\">\r\n    <!-- <span>{{'peak:hello' | translate}}, </span> -->\r\n    <span class=\"bold\">{{profile.user!.name || ''}} {{profile.user!.surname || ''}}</span>\r\n  </div>\r\n  <div *ngIf=\"profile\" class=\"dropdown pull-right\">\r\n    <button class=\"profile-dropdown-toggle dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\"\r\n      aria-expanded=\"false\" (click)=\"isOpened = !isOpened\">\r\n      <span class=\"thumbnail-wrapper d32 circular inline m-t-5\">\r\n        <img [src]=\"profile.user!.profileImageUri || '../../../../assets/img/user-icon.png'\">\r\n      </span>\r\n    </button>\r\n    <div class=\"dropdown-menu dropdown-menu-right profile-dropdown\" [class.show]=\"isOpened\" role=\"menu\">\r\n      <ng-container *ngFor=\"let action of actions\">\r\n        <peak-user-action [value]=\"action\"></peak-user-action>\r\n      </ng-container>\r\n    </div>\r\n  </div>"
    }]
  }], function () {
    return [];
  }, {
    profile: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    isOpened: [{
      type: Input
    }]
  });
})();
class PeakHeaderComponent {
  constructor() {
    this.showLogo = true;
    this.onLogin = new EventEmitter();
  }
  _onLogin() {
    if (this.onLogin) this.onLogin.emit();
  }
  ngOnInit() {}
}
PeakHeaderComponent.ɵfac = function PeakHeaderComponent_Factory(t) {
  return new (t || PeakHeaderComponent)();
};
PeakHeaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PeakHeaderComponent,
  selectors: [["peak-header"]],
  inputs: {
    showLogo: "showLogo",
    logoUri: "logoUri",
    profile: "profile",
    actions: "actions"
  },
  outputs: {
    onLogin: "onLogin"
  },
  decls: 14,
  vars: 4,
  consts: [[1, "header"], [1, "pull-left", "full-height", "visible-sm", "visible-xs"], [1, "header-inner"], ["href", "#", "data-toggle", "sidebar", 1, "btn-link", "toggle-sidebar", "visible-sm-inline-block", "visible-xs-inline-block", "padding-5"], [1, "icon-set", "menu-hambuger"], [1, "pull-center", "d-md-none", "d-lg-none"], ["class", "brand inline", 4, "ngIf"], [1, "pull-left", "sm-table", "d-xs-none"], ["class", "brand", 4, "ngIf"], [1, "pull-right"], ["class", "visible-lg visible-md m-t-10", 4, "ngIf"], ["class", "login-container", 4, "ngIf"], [1, "brand", "inline"], ["alt", "logo", "height", "25", 3, "src"], [1, "brand"], ["alt", "logo", "height", "30", 3, "src"], [1, "visible-lg", "visible-md", "m-t-10"], [3, "profile", "actions"], [1, "login-container"], ["href", "javascript:void()", 3, "click"]],
  template: function PeakHeaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "a", 3);
      i0.ɵɵelement(4, "span", 4);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(5, "div", 5)(6, "div", 2);
      i0.ɵɵtemplate(7, PeakHeaderComponent_div_7_Template, 2, 1, "div", 6);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(8, "div", 7)(9, "div", 2);
      i0.ɵɵtemplate(10, PeakHeaderComponent_div_10_Template, 3, 1, "div", 8);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(11, "div", 9);
      i0.ɵɵtemplate(12, PeakHeaderComponent_div_12_Template, 2, 2, "div", 10)(13, PeakHeaderComponent_div_13_Template, 3, 1, "div", 11);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(7);
      i0.ɵɵproperty("ngIf", ctx.showLogo);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.showLogo);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.profile);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.profile);
    }
  },
  dependencies: [i1.NgIf, PeakUserComponent],
  styles: [".header[_ngcontent-%COMP%]{position:fixed;left:0;top:0;height:60px;width:100%;padding:0 20px 0 0;z-index:800;background-color:#fff;border-bottom:1px solid rgba(230,230,230,.7)}.header[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#3b4751}.login-container[_ngcontent-%COMP%]{margin-top:18px}.header[_ngcontent-%COMP%]   .pull-left[_ngcontent-%COMP%], .header[_ngcontent-%COMP%]   .pull-right[_ngcontent-%COMP%]{z-index:10;position:relative}.full-height[_ngcontent-%COMP%]{height:100%!important}.header[_ngcontent-%COMP%]   .header-inner[_ngcontent-%COMP%]{height:60px;width:100%;vertical-align:middle;display:table-cell}.header-inner[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]{margin-top:10px}.header[_ngcontent-%COMP%]   .brand[_ngcontent-%COMP%]{vertical-align:middle;width:280px;text-align:center}@media only screen and (min-width: 980px){.header[_ngcontent-%COMP%]   .brand[_ngcontent-%COMP%]{position:relative}}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'peak-header',
      template: "<div class=\"header \">\r\n    <!-- START MOBILE CONTROLS -->\r\n    <!-- <div class=\"container-fluid relative\"> -->\r\n    <!-- LEFT SIDE -->\r\n    <div class=\"pull-left full-height visible-sm visible-xs\">\r\n      <!-- START ACTION BAR -->\r\n      <div class=\"header-inner\">\r\n        <a href=\"#\" class=\"btn-link toggle-sidebar visible-sm-inline-block visible-xs-inline-block padding-5\"\r\n          data-toggle=\"sidebar\">\r\n          <span class=\"icon-set menu-hambuger\"></span>\r\n        </a>\r\n      </div>\r\n      <!-- END ACTION BAR -->\r\n    </div>\r\n    <div class=\"pull-center d-md-none d-lg-none\">\r\n      <div class=\"header-inner\">\r\n        <div class=\"brand inline\" *ngIf=\"showLogo\">\r\n          <img [src]=\"logoUri\" alt=\"logo\" height=\"25\">\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"pull-left sm-table d-xs-none\">\r\n      <div class=\"header-inner\"> \r\n        <div class=\"brand\" *ngIf=\"showLogo\">\r\n          <a>\r\n            <img [src]=\"logoUri\" alt=\"logo\" height=\"30\">\r\n          </a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\" pull-right\">\r\n      <!-- START User Info-->\r\n      <div *ngIf=\"profile\" class=\"visible-lg visible-md m-t-10\">\r\n        <peak-user  [profile]=\"profile\" [actions]=\"actions\"></peak-user>\r\n      </div>\r\n      <div class=\"login-container\" *ngIf=\"!profile\">\r\n        <a href=\"javascript:void()\" (click)=\"_onLogin()\">{{\"peak:login\" }}</a>\r\n      </div>\r\n      <!-- END User Info-->\r\n    </div>\r\n  </div> ",
      styles: [".header{position:fixed;left:0;top:0;height:60px;width:100%;padding:0 20px 0 0;z-index:800;background-color:#fff;border-bottom:1px solid rgba(230,230,230,.7)}.header a{color:#3b4751}.login-container{margin-top:18px}.header .pull-left,.header .pull-right{z-index:10;position:relative}.full-height{height:100%!important}.header .header-inner{height:60px;width:100%;vertical-align:middle;display:table-cell}.header-inner>a{margin-top:10px}.header .brand{vertical-align:middle;width:280px;text-align:center}@media only screen and (min-width: 980px){.header .brand{position:relative}}\n"]
    }]
  }], function () {
    return [];
  }, {
    showLogo: [{
      type: Input
    }],
    logoUri: [{
      type: Input
    }],
    profile: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    onLogin: [{
      type: Output
    }]
  });
})();
class PeakUserAction {
  constructor(text, type, icon, iconType, action) {
    this.text = text;
    this.type = type;
    this.icon = icon;
    this.iconType = iconType;
    this.onCommand = new EventEmitter();
    if (action) this.onCommand.subscribe(action);
  }
  command() {
    if (this.onCommand) this.onCommand.emit(this);
  }
}

// @dynamic
class PeakConstants {}
PeakConstants.PeakLanguages = "PeakLanguages";
PeakConstants.PeakResources = "PeakResources";
PeakConstants.PeakSidebarGroups = "PeakSidebarGroups";
PeakConstants.PeakUserActions = "PeakUserActions";
PeakConstants.PeakAuth = "PeakAuth";
PeakConstants.PeakData = "PeakData";
const PAC = PeakConstants;
let m = PeakAuth;
try {
  console.log("m", m);
  console.log("m.default", m.default);
  console.log("m.defaulttype", typeof m.default);
  console.log("m type ", typeof m);
  new PeakAuth();
} catch (error) {
  console.log(error);
}
const PeakAuthToken = new InjectionToken(PAC.PeakAuth);
const PeakAuthInstance = typeof m == "function" ? new m() : new m.default();
console.log("authInstanceLog", PeakAuthInstance);
const PeakAuthProvider = {
  provide: PeakAuthToken,
  useValue: PeakAuthInstance
};

// @dynamic
class PeakDefaults {}
PeakDefaults.TurkishResourceKey = "tr";
PeakDefaults.TurkishLanguageName = "Türkçe";
PeakDefaults.TurkishLanguageIconType = 'Font';
PeakDefaults.TurkishLanguageIcon = "flag-icon flag-icon-tr";
PeakDefaults.TurkishLanguageNotificationTitle = "Dil değiştirildi";
PeakDefaults.TurkishLanguageNotificationContent = "Dil Türkçe olarak değiştirildi";
PeakDefaults.EnglishResourceKey = "en";
PeakDefaults.EnglishLanguageName = "English";
PeakDefaults.EnglishLanguageIconType = 'Font';
PeakDefaults.EnglishLanguageIcon = "flag-icon flag-icon-gb";
PeakDefaults.EnglishLanguageNotificationTitle = "Changed language";
PeakDefaults.EnglishLanguageNotificationContent = "Changed language to English";
PeakDefaults.DefaultLanguage = PeakDefaults.TurkishResourceKey;
PeakDefaults.$meta = "$meta";
PeakDefaults.$default = "$default";
PeakDefaults.ReauthAsAdminUserActionKey = "reauthAsAdmin";
PeakDefaults.LogoutUserActionKey = "logout";
PeakDefaults.PeakApplicationResourceKey = "app";
PeakDefaults.PeakApplication = "peak:application";
PeakDefaults.PeakServices = "peak:services";
PeakDefaults.PeakLanguage = "peak:language";
PeakDefaults.PeakDataServiceToken = "38F72C70-5996-41F6-8253-DF48A47BF450";
const PAD = PeakDefaults;
let n = PeakData;
const PeakDataToken = new InjectionToken(PAC.PeakData);
const PeakDataInstance = typeof n == "function" ? new n(undefined, PAD.PeakDataServiceToken) : new n.default(undefined, PAD.PeakDataServiceToken);
const PeakDataProvider = {
  provide: PeakDataToken,
  useValue: PeakDataInstance
};
const PeakDefaultUserActionResource = {
  [PAD.ReauthAsAdminUserActionKey]: new PeakUserAction("peak:reauthAsAdmin", 'Normal', 'ion ion-gear-a', 'Font'),
  [PAD.LogoutUserActionKey]: new PeakUserAction("peak:logout", 'Master', 'pg-power', 'Font')
};
const PeakUserActionsToken = new InjectionToken(PAC.PeakUserActions);
const PeakUserActionsResourceGroups = {
  $default: PeakDefaultUserActionResource
};
const PeakUserActionsProvider = [{
  provide: PeakUserActionsToken,
  useValue: PeakUserActionsResourceGroups
}];
class PeakResourceManager {
  constructor(resources) {
    this.resources = resources;
    this._currentSubject = new Subject();
  }
  get current() {
    return this._current;
  }
  set current(value) {
    this._current = value;
    this._currentSubject.next(this._current);
  }
  get onCurrentChanged() {
    return this._currentSubject.asObservable();
  }
  resource(key) {
    if (key === PAD.$meta) return;
    return this.resources[key];
  }
  instant(key) {
    if (key === PAD.$meta) return;
    if (!this.current) return key;
    const value = this.current[key];
    return value !== undefined ? value : key;
  }
  use(resourceKey) {
    this.current = this.resource(resourceKey) || this.current;
  }
}

// import {
class PeakDefaultResourceManager extends PeakResourceManager {
  constructor(resources) {
    super(resources);
    this.current = this.default;
  }
  get default() {
    return this.resources.$default;
  }
}
class PeakUserActionService extends PeakDefaultResourceManager {
  constructor(resources) {
    super(resources);
  } //Bir alt-sınıf ne zaman üst-sınıfına erişmek isterse super anahtar sözcüğünü kullanabilir. 
}
PeakUserActionService.ɵfac = function PeakUserActionService_Factory(t) {
  return new (t || PeakUserActionService)(i0.ɵɵinject(PeakUserActionsToken));
};
PeakUserActionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PeakUserActionService,
  factory: PeakUserActionService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakUserActionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PeakUserActionsToken]
      }]
    }];
  }, null);
})();
class PeakAuthService {
  constructor(auth, userActions) {
    this.auth = auth;
    this.userActions = userActions;
    this.onLogout = new EventEmitter();
    this.onReauthAsAdmin = new EventEmitter();
  }
  _onLogout() {
    if (this.onLogout) this.onLogout.emit();
  }
  _onReauthAsAdmin() {
    if (this.onReauthAsAdmin) this.onReauthAsAdmin.emit();
  }
  async resolveMeAsync() {
    this.me = await this.auth.clients.me.getAsync();
    return this.me;
  }
  async clearAdalCacheFromLocalStorage() {
    if (localStorage) {
      Object.keys(localStorage).filter(key => key.indexOf("adal") > -1).forEach(key => localStorage.removeItem(key));
    }
  }
  async resolveUserActions(redirect = true) {
    const reauthAsAdmin = this.userActions.instant(PeakDefaults.ReauthAsAdminUserActionKey);
    if (reauthAsAdmin instanceof PeakUserAction) reauthAsAdmin.onCommand.subscribe(_ => {
      this.auth.reauth(true, redirect);
      this._onReauthAsAdmin();
      this.clearAdalCacheFromLocalStorage();
      localStorage.removeItem("isSilentLoginAvailable");
      this.me = null;
    });
    const logout = this.userActions.instant(PeakDefaults.LogoutUserActionKey);
    if (logout instanceof PeakUserAction) logout.onCommand.subscribe(_ => {
      this.auth.reauth(undefined, redirect);
      this._onLogout();
      this.clearAdalCacheFromLocalStorage();
      localStorage.removeItem("isSilentLoginAvailable");
      this.me = null;
    });
  }
}
PeakAuthService.ɵfac = function PeakAuthService_Factory(t) {
  return new (t || PeakAuthService)(i0.ɵɵinject(PeakAuthToken), i0.ɵɵinject(PeakUserActionService));
};
PeakAuthService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PeakAuthService,
  factory: PeakAuthService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PeakAuth,
      decorators: [{
        type: Inject,
        args: [PeakAuthToken]
      }]
    }, {
      type: PeakUserActionService
    }];
  }, null);
})();
class PeakDataService {
  constructor(data) {
    this.data = data;
  }
}
PeakDataService.ɵfac = function PeakDataService_Factory(t) {
  return new (t || PeakDataService)(i0.ɵɵinject(PeakDataToken));
};
PeakDataService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PeakDataService,
  factory: PeakDataService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakDataService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PeakData,
      decorators: [{
        type: Inject,
        args: [PeakDataToken]
      }]
    }];
  }, null);
})();
class PeakShellService {
  constructor(authService, userActionServices) {
    this.authService = authService;
    this.userActionServices = userActionServices;
    this.onLoginCompleted = new EventEmitter();
    this.onLogoutCompleted = new EventEmitter();
    this.loginType = 'Native';
    this.teams = teams;
    this.authService.onLogout.subscribe(() => this._onLogoutCompleted());
    this.authService.onReauthAsAdmin.subscribe(() => this._onLogoutCompleted());
  }
  _onLoginCompleted(e) {
    if (this.onLoginCompleted) this.onLoginCompleted.emit(e);
  }
  _onLogoutCompleted() {
    if (this.onLogoutCompleted) this.onLogoutCompleted.emit();
  }
  initializeTeamsAsync() {
    let self = this;
    return new Promise((resolve, reject) => {
      try {
        let isResolved = false;
        self.teams.initialize(() => {
          isResolved = true;
          resolve();
        });
        setTimeout(() => {
          if (!isResolved) reject("timeout");
        }, 250);
      } catch (e) {
        reject(e);
      }
    });
  }
  async initAsync() {
    try {
      await this.initializeTeamsAsync();
      this.loginType = 'Teams';
    } catch (e) {
      console.log("Error on teams init", e);
    }
  }
  get profile() {
    return this.authService.me;
  }
  get actions() {
    return Object.values(this.userActionServices.current).filter(x => x instanceof PeakUserAction);
  }
  getTeamsAuthToken() {
    return new Promise((resolve, reject) => {
      this.teams.authentication.getAuthToken({
        successCallback: token => {
          resolve({
            status: true,
            result: token
          });
        },
        failureCallback: reason => {
          resolve({
            status: false,
            result: reason
          });
        }
      });
    });
  }
  async setApplicationName(name, resolve = true) {
    if (name === undefined && resolve && this.authService) {
      const service = await this.authService.auth.clients.meta.serviceAsync();
      name = service.name;
    }
  }
  async resolveAuth(applicationName) {
    if (this.loginType == 'Teams') {
      let teamsAuth = await this.getTeamsAuthToken(); // teams is teams tekone getiriyor 
      if (teamsAuth.status) {
        let tokenModel = await this.authService.auth.clients.oauth.azureAdAsync(teamsAuth.result);
        if (tokenModel.error) {
          this._onLoginCompleted({
            status: false,
            loginType: 'Teams',
            error: tokenModel.error
          });
          return;
        }
        // console.log("processTokenAsync for Teams - result", tokenModel);
        this.authService.auth.handleToken(tokenModel);
        localStorage.setItem("isSilentLoginAvailable", "true"); // temas içib kullanıcıya başltaki süreci yaşamasın diye hali hazırdaki tokenı kullanıyor 
        this._onLoginCompleted({
          status: true,
          loginType: 'Teams'
        });
      } else {
        this._onLoginCompleted({
          status: false,
          loginType: 'Teams',
          error: teamsAuth.result
        });
        return;
      }
    } else {
      await this.authService.auth.processAsync();
      this._onLoginCompleted({
        status: true,
        loginType: 'Native'
      });
    }
    // console.log("Resolving me.");
    // let me = await this.authService.resolveMeAsync();
    // console.log("Resolved me.", me);
    this.setApplicationName(applicationName);
    // console.log("Resolving user actions");
    await this.authService.resolveUserActions(this.loginType == 'Native');
    console.log("Resolved user actions", this.authService.resolveUserActions);
    // console.log("Resolving resources.");
    // await this.authService.resolveResources();
    // console.log("Resolved resources.");
    // console.log("Resolving services.");
    // await this.authService.resolveServicesAsync();
    // console.log("Resolved services.",);
    // return me;
  }
  async login(applicationName) {
    await this.resolveAuth(applicationName);
  }
}
PeakShellService.ɵfac = function PeakShellService_Factory(t) {
  return new (t || PeakShellService)(i0.ɵɵinject(PeakAuthService), i0.ɵɵinject(PeakUserActionService));
};
PeakShellService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PeakShellService,
  factory: PeakShellService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakShellService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PeakAuthService
    }, {
      type: PeakUserActionService
    }];
  }, null);
})();
class PeakShellComponent {
  constructor(service) {
    this.service = service;
    this.name = undefined;
    this.autoLogin = true;
    this.showLogo = true;
    this.showHeader = true;
    // @Input() public showSidebar: boolean = true;
    this.showLoading = true;
    // @Input() public languages: Array<PeakLanguageMeta>;
    this.onResolvedProfile = new EventEmitter();
    this.onLoginActions = new EventEmitter();
    this.onLogoutActions = new EventEmitter();
    service.initAsync().finally(() => {
      if (service.loginType == 'Teams') {
        this.autoLogin = false;
      }
      service.setApplicationName(this.name, false);
      service.onLoginCompleted.subscribe(e => {
        if (e.status) {
          service.authService.resolveMeAsync().then(profile => {
            this.profile = profile;
            this.onResolvedProfile.emit(profile);
          });
        }
      });
      service.authService.onLogout.subscribe(() => this.profile = null);
      service.authService.onReauthAsAdmin.subscribe(() => this.profile = null);
      if (this.autoLogin && service.loginType == 'Native' || service.loginType == 'Teams' && localStorage.getItem('isSilentLoginAvailable') == 'true') {
        console.log('Calling login');
        this.login();
      }
    });
  }
  get resolvedProfile() {
    return this.profile || this.service.profile;
  }
  get resolvedActions() {
    return this.actions || this.service.actions;
  }
  ngOnInit() {
    console.log('resolvedActions-shell.ts', this.resolvedActions);
    this.onLoginActions.emit(this.resolvedActions);
    this.onLogoutActions.emit(this.resolvedActions);
  }
  login() {
    console.log('Login called');
    this.service.resolveAuth(this.name);
  }
}
PeakShellComponent.ɵfac = function PeakShellComponent_Factory(t) {
  return new (t || PeakShellComponent)(i0.ɵɵdirectiveInject(PeakShellService));
};
PeakShellComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PeakShellComponent,
  selectors: [["peakShell"]],
  inputs: {
    name: "name",
    autoLogin: "autoLogin",
    showLogo: "showLogo",
    showHeader: "showHeader",
    showLoading: "showLoading",
    profile: "profile",
    logoUri: "logoUri",
    loadingUri: "loadingUri",
    actions: "actions"
  },
  outputs: {
    onResolvedProfile: "onResolvedProfile",
    onLoginActions: "onLoginActions",
    onLogoutActions: "onLogoutActions"
  },
  decls: 1,
  vars: 1,
  consts: [[3, "showLogo", "profile", "actions", "onLogin", 4, "ngIf"], [3, "showLogo", "profile", "actions", "onLogin"]],
  template: function PeakShellComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PeakShellComponent_peak_header_0_Template, 1, 3, "peak-header", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.showHeader);
    }
  },
  dependencies: [i1.NgIf, PeakHeaderComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakShellComponent, [{
    type: Component,
    args: [{
      selector: 'peakShell',
      template: "<peak-header\r\n *ngIf=\"showHeader\"\r\n  [showLogo]=\"showLogo\"\r\n   [profile]=\"profile!\"\r\n  [actions]=\"resolvedActions\"  \r\n   (onLogin)=\"login()\"\r\n   > \r\n  </peak-header>\r\n"
    }]
  }], function () {
    return [{
      type: PeakShellService
    }];
  }, {
    name: [{
      type: Input
    }],
    autoLogin: [{
      type: Input
    }],
    showLogo: [{
      type: Input
    }],
    showHeader: [{
      type: Input
    }],
    showLoading: [{
      type: Input
    }],
    profile: [{
      type: Input
    }],
    logoUri: [{
      type: Input
    }],
    loadingUri: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    onResolvedProfile: [{
      type: Output
    }],
    onLoginActions: [{
      type: Output
    }],
    onLogoutActions: [{
      type: Output
    }]
  });
})();
const PeakConfigProvider = new InjectionToken("PeakAngularConfig");
class PeakShellModule {
  static forRoot(config) {
    let authBaseUri = undefined;
    let authService = undefined;
    let authRedirectUri = undefined;
    let dataBaseUri = undefined;
    let dataToken = PAD.PeakDataServiceToken;
    if (config.auth !== undefined) {
      if (config.auth.baseUri !== undefined) authBaseUri = config.auth.baseUri;
      if (config.auth.service !== undefined) authService = config.auth.service;
      if (config.auth.redirectUri !== undefined) authRedirectUri = config.auth.redirectUri;
    }
    if (config.data !== undefined) {
      if (config.data.baseUri !== undefined) dataBaseUri = config.data.baseUri;
      if (config.data.token !== undefined) dataToken = config.data.token;
    }
    let a = PeakAuth;
    const PeakAuthInstance = typeof a == "function" ? new a(authBaseUri, authService, authRedirectUri) : new a.default(authBaseUri, authService, authRedirectUri);
    const PeakAuthInstanceData = typeof a == "function" ? new a(dataBaseUri, dataToken) : new a.default(dataBaseUri, dataToken);
    return {
      ngModule: PeakShellModule,
      providers: [{
        provide: PeakConfigProvider,
        useValue: config
      }, {
        provide: PeakAuthToken,
        useValue: PeakAuthInstance
      }, {
        provide: PeakDataToken,
        useValue: PeakAuthInstanceData
      }]
    };
  }
}
PeakShellModule.ɵfac = function PeakShellModule_Factory(t) {
  return new (t || PeakShellModule)();
};
PeakShellModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PeakShellModule
});
PeakShellModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [PeakAuthService, PeakUserActionService, PeakDataService, PeakAuthProvider, PeakUserActionsProvider],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PeakShellModule, [{
    type: NgModule,
    args: [{
      declarations: [PeakShellComponent, PeakHeaderComponent, PeakUserComponent, PeakUserActionComponent, PeakHeaderComponent, PeakUserComponent, PeakUserActionComponent],
      imports: [CommonModule],
      exports: [PeakShellComponent, PeakHeaderComponent, PeakUserComponent, PeakUserActionComponent],
      providers: [PeakAuthService, PeakUserActionService, PeakDataService, PeakAuthProvider, PeakUserActionsProvider]
    }]
  }], null, null);
})();

/*
 * Public API Surface of peak-shell
 */

/**
 * Generated bundle index. Do not edit.
 */

export { PAC, PAD, PeakAuthProvider, PeakAuthService, PeakAuthToken, PeakConstants, PeakDataProvider, PeakDataService, PeakDataToken, PeakDefaultResourceManager, PeakDefaultUserActionResource, PeakDefaults, PeakHeaderComponent, PeakResourceManager, PeakShellComponent, PeakShellModule, PeakShellService, PeakUserAction, PeakUserActionComponent, PeakUserActionService, PeakUserActionsProvider, PeakUserActionsResourceGroups, PeakUserActionsToken, PeakUserComponent };
