"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class PeakAuthWith {
    constructor(auth) {
        this.auth = auth;
    }
    azureAdAsync(service, redirectUri, loginHint, adminConsent, redirect = true, autoLogin = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.auth.resolver.isAuth()) {
                if (loginHint)
                    this.auth.resolver.cache.loginHint.value = loginHint;
                this.auth.resolver.cache.provider.value = 'azuread';
                this.auth.handleWith(yield this.auth.clients.with.azureAdAsync(service, redirectUri, loginHint, adminConsent), redirect);
            }
            else
                yield this.auth.reauthAsync(adminConsent, redirect, autoLogin);
        });
    }
    googleAsync(service, redirectUri, loginHint, adminConsent, redirect = true, autoLogin = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.auth.resolver.isAuth()) {
                if (loginHint)
                    this.auth.resolver.cache.loginHint.value = loginHint;
                this.auth.resolver.cache.provider.value = 'google';
                this.auth.handleWith(yield this.auth.clients.with.googleAsync(service, redirectUri, loginHint, adminConsent), redirect);
            }
            else
                yield this.auth.reauthAsync(adminConsent, redirect, autoLogin);
        });
    }
    nativeAsync(service, redirectUri, username, password, redirect = true, autoLogin = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.auth.resolver.isAuth()) {
                if (username)
                    this.auth.resolver.cache.loginHint.value = username;
                this.auth.resolver.cache.provider.value = 'native';
                this.auth.handleWith(yield this.auth.clients.with.nativeAsync(service, redirectUri, username, password), redirect);
            }
            else
                yield this.auth.reauthAsync(undefined, redirect, autoLogin);
        });
    }
    passwordAsync(service, username, password) {
        return __awaiter(this, void 0, void 0, function* () {
            this.auth.handleToken(yield this.auth.clients.with.passwordAsync(service, username, password));
        });
    }
    credentialAsync(service, key, secret) {
        return __awaiter(this, void 0, void 0, function* () {
            this.auth.handleToken(yield this.auth.clients.with.credentialAsync(service, key, secret));
        });
    }
}
exports.default = PeakAuthWith;
