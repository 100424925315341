import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PeakAuthService } from 'peak-shell';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public headers: HttpHeaders = new HttpHeaders();
  authToken: any = localStorage.getItem('peakauth:tokenResponse');

  constructor(private peakAuthService: PeakAuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: this.headers,
    });

    this.authToken = localStorage.getItem('peakauth:tokenResponse');

    if (this.authToken) {
      const token = JSON.parse(this.authToken);
      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.access_token}`,
        },
      });

      return next.handle(authReq).pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.logout();
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  logout() {
    console.log('Logging out...');
    // await this.peakAuthService.auth.clients.logout.postAsync();
    localStorage.clear();
    window.location.reload();
  }
}
