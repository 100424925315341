import { Component, OnInit, inject } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingService } from '../../services/loading.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'vlc-loading',
  standalone: true,
  imports: [ProgressSpinnerModule, NgIf],
  template: `
    <div class="spinner-container" [class.hide]="!isLoading">
      <p-progressSpinner
        *ngIf="isLoading"
        [style]="{ width: '96x', height: '96px' }"
      ></p-progressSpinner>
    </div>
  `,
  styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit {
  private loadingService = inject(LoadingService);
  isLoading?: boolean;

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((value) => {
      this.isLoading = value;
    });
  }
}
