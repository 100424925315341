"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PeakAuthDefaults {
}
exports.default = PeakAuthDefaults;
PeakAuthDefaults.Service = "suite";
PeakAuthDefaults.LocalEnvironment = "local";
PeakAuthDefaults.ProdEnvironment = "prod";
PeakAuthDefaults.BaseUri = "https://auth.peakup.org";
PeakAuthDefaults.BaseUriEnvironmentFormat = "https://auth-{environment}.peakup.org";
PeakAuthDefaults.RedirectUri = "https://suite.peakup.org";
PeakAuthDefaults.AutoLogin = true;
PeakAuthDefaults.AdminConsent = false;
