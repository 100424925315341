"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Observer {
    constructor() {
        this.handlers = new Array();
    }
    subscribe(func) {
        this.handlers.push(func);
    }
    unsubscribe(func) {
        this.handlers = this.handlers.filter(handler => {
            if (handler != func)
                return handler;
        });
    }
    fire(param) {
        this.handlers.forEach(handler => {
            handler(param);
        });
    }
}
exports.default = Observer;
